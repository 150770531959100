.contactPage {
    padding-top: 130px;
}
.contactPage .heading {
    font-family: poppinsBold;
    font-size: 55px;
    color: #001939;
    margin-bottom: 10px;
    text-align: center;
}
.contactPage .title {
    font-family: poppins;
    font-size: 20px;
    color: #7A7A7A;
    margin: auto;
    margin-bottom: 40px;
    text-align: center;
    max-width: 730px;
}
.contactPage .formMain {
    background: #F6F6F6;
    border-radius: 18px;
    padding: 30px 20px;
    margin-bottom: 40px;
}
.contactPage .formMain .inputM {
    margin-bottom: 20px;
}
.contactPage .formMain label {
    font-size: 18px;
    font-weight: 500;
    font-family: PoppinsBold;
}
.contactPage .formMain input::placeholder {
    color: #C2C2C2;
}
.contactPage .formMain .row {
    margin-left: 0;
    margin-right: 0;
}
.contactPage .formMain input {
    width: 100%;
    padding:  12px;
    border: none;
    outline: none;
    border-radius: 12px;
}
.contactPage .formMain select::placeholder {
    color: #C2C2C2;
}
.contactPage .formMain select {
    width: 100%;
    padding:  12px;
    border: none;
    outline: none;
    border-radius: 12px;
}
.contactPage .formMain .yesNoBtn button.active {
    background: #08B882;
}
.contactPage .formMain .yesNoBtn button {
    background: #C2C2C2;
    border-radius: 10px;
    font-family: poppinsBold;
    color: #fff;
    max-width: 105px;
    width: 100%;
    padding: 10px;
    border: none;
}
.contactPage .submitBtn {
    padding: 40px 0;
}
.contactPage .submitBtn button{
  
    background: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%) 0 100% transparent no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    padding: 10px 28px;
    color: white;
    border: none;
    /* border: 2px solid transparent; */
  
  /* background-image: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%); */
    border-radius: 10px;
      padding: 10px 28px;
      color: white;
    /* border: none; */
    height: 44px;
    min-width: 139px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all 0.3s
    
  }
  .contactPage .submitBtn button:hover{
    
    background: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%) 0 100% transparent no-repeat;
    background-color: white;
    background-size: 0% 100%;
    border-radius: 10px;
    /* border: 2px solid #001939; */
    border: 2px solid #05EC65;
    color: #001939;
    transition:all 0.3s;
    /* border: none; */
    /* border: 1px solid #00738E; */
    text-decoration: none;
  
  }
  .contactPage .submitBtn button:focus {
    box-shadow: none !important;
  }