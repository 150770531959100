.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(198, 198, 198);
  /* outline: 1px solid slategrey; */
  border-radius: 8px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}






.page2sec1 {
  /* background: linear-gradient(471deg, #02828B 11.68%, hsl(145, 96%, 47%) 59%); */
  background: linear-gradient(471deg, #00738E 0%, #08B882 60%, #05EC65 100%);
}
.w-100 {
  width: 100%;
}
.page2sec1 h1,
.page2sec1 h2,
.page2sec1 h3,
.page2sec1 h4 {
  font-family: PoppinsBold;
  font-weight: bold;
}

.page2sec2 h1,
.page2sec2 h2,
.page2sec2 h3,
.page2sec2 h4 {
  font-family: PoppinsBold;
  font-weight: bold;
}

.page2sec1 p,
.page2sec2 p {
  font-family: Poppins;
}

.page2sec1 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.page2sec2 .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.page2sec2 {
  /* padding-top: 50px; */
  /* background: linear-gradient(471deg, #02828B 11.68%, hsl(145, 96%, 47%) 59%); */
  background: linear-gradient(471deg, #00738E 0%, #08B882 60%, #05EC65 100%);
}

.dollar {
  width: 100%;
  padding-right: 12px;
  padding-top: 2px;
}

.create {
  background-color: white;
  height: auto;
  padding-bottom: 20px;
  width: 500px;
  border-radius: 50px;
  margin-top: 50px;
  margin-bottom: 50px;

}

.create1 {
  background-color: white;
  height: auto;
  padding-bottom: 20px;
  width: 500px;
  border-radius: 50px;
  margin-top: 50px;
  margin-bottom: 50px;

}

.firstdiv {
  padding-left: 10px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: 1px solid grey;
  border: none;
  margin-left: 15px;
  width: 245px;
  margin-top: 30px;
}

.lastdiv {
  padding-left: 10px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: 1px solid grey;
  border: none;
  margin-left: 20px;

  width: 245px;
  margin-top: 30px;
}

.secdiv {
  margin-top: 30px;
  padding-left: 10px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: 1px solid grey;
  border: none;
  margin-left: 15px;
  width: 511px;
}

.thirddiv {
  margin-top: 30px;
  padding-left: 10px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: 1px solid grey;
  border: none;
  margin-left: 15px;
  width: 511px;
}

.sigup {
  text-align: center;
  margin-top: 25px;
  color: #202020;
}

.sigupp {
  text-align: center;
}

.sigupp span {
  cursor: pointer;
  color: #08B882;
}

.passError {
  font-size: 12px;
  margin-left: 15px;
  margin-right: 15px;
  color: rgb(216, 0, 0);
  margin-top: 4px;
}

.small {
  text-align: center;
  justify-content: center;
  margin-top: 20px;
}

.crebtn {
  color: white;
  background: linear-gradient(90deg, #05EC65 -52.32%, rgb(0, 115, 142) 100%);
  text-align: -webkit-center;
  justify-content: center;
  padding: 10px 31px;
  border: none;
  border-radius: 16px;

}

.btnnncre {
  text-align: center;
}

.sectop {
  margin-top: 117px;
}

.signinh1 {
  text-align: center;
  margin-top: 55px;
  color: #202020;
}

.signinpp {
  text-align: center;
  cursor: pointer;
  color: #202020;
}

.ppp {
  text-align: center;
  margin-top: 80px;
  font-size: 12px;
  color: #84818A;
}

.ppp span {
  color: #08B882;
}

.forgot {
  margin-left: 17px;
  margin-top: 20px;
  display: inline-block;
}

.verifyPage {
  min-height: 100vh;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.verifyPage h1 {
  font-size: 60px;
}

.loaderStyle {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #000;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.crebtn1 {
  color: white;
  background: linear-gradient(90deg, #04EC65 -52.32%, rgb(0, 115, 142) 100%);
  text-align: -webkit-center;
  justify-content: center;
  padding: 10px 31px;
  border: none;
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 5px;
}

.crebtn2 {
  color: white;
  background: linear-gradient(90deg, #05EC65 -52.32%, rgb(0, 115, 142) 100%);
  text-align: -webkit-center;
  justify-content: center;
  padding: 10px 31px;
  border: none;
  border-radius: 16px;
  margin-left: 20px;
  margin-top: 5px;
}

.eye {
  margin-top: -66px;
  margin-left: 488px;
}


.sectop1 {
  margin-top: 117px;
}

.sec9 {
  background-color: #001939;
  color: white;
  padding-top: 73px;
  padding-bottom: 2px;
  /* border-top-left-radius: 60px;
  border-top-right-radius: 60px; */
  /* background-image: linear-gradient(to right, #03d159, #00738E); */
}

.footermain {
  display: flex;
  justify-content: space-between;
}

.footermain a {
  color: white;
}

.footermain a:hover {
  opacity: 0.8;
}

.ffp {

  margin-top: 23px;
}

.pfot {
  text-align: center;
  color: white;
  padding-top: 50px;
  padding-bottom: 2px;
}

.CheckoutMain {
  padding: 60px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.ai-center {
  align-items: center;
}

.CheckoutMain .effect {
  position: absolute;
  height: 100%;
  width: 50%;
  background-image: linear-gradient(to bottom, #03d159, #00738E);
  clip-path: polygon(0% 0%, 73% 0%, 100% 100%, 0% 100%);
}

.CheckoutMain .headingMain {
  color: white;
}

.CheckoutMain .headingMain h1 {
  font-size: 80px;
  font-weight: bold;
}

.CheckoutMain .headingMain h2 {
  font-size: 50px;
}

.CheckoutMain .card-body {
  width: 100%;
  height: auto;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #e5f8f0;
  border-radius: 20px;
}

.CheckoutMain .card-body .card-title {
  width: 100%;
  font-weight: 900;
  color: #00947c;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 0;
}

.CheckoutMain .card-body .card-text {
  width: 100%;
  color: #7280a7;
  text-align: center;
  line-height: 25px;
  padding: 15px 0px;
  box-sizing: border-box;
}

.CheckoutMain .card-body .form-group input {
  background: #fff;
  border-radius: 10px;
  border: none;
  color: #858796;
  width: 100px;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
.CheckoutMain .card-body .form-group input::-webkit-outer-spin-button,
.CheckoutMain .card-body .form-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.CheckoutMain .card-body .form-group input[type=number] {
  -moz-appearance: textfield;
}

.CheckoutMain .card-body .form-group input::placeholder {

  color: #858796;
}

.CheckoutMain .card-body .card-plan {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 15px;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
}

.CheckoutMain .card-body .card-plan .card-plan-img {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.CheckoutMain .card-body .card-plan .card-plan-text {
  flex-grow: 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
}

.CheckoutMain .card-body .card-plan .card-plan-text .card-plan-title {
  color: #00947c;
  font-weight: 900;
  font-size: 14px;
}

.CheckoutMain .card-body .card-plan .card-plan-text .card-plan-price {
  color: var(--desaturated-blue);
  font-size: 14px;
}

.CheckoutMain .card-body .card-plan .card-plan-link {
  flex-grow: 1;
}

.CheckoutMain .card-body .card-plan .card-plan-link a {
  color: var(--bright-blue);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.CheckoutMain .card-body .card-plan .card-plan-link a:hover {
  color: #766cf1;
  text-decoration: none;
  ;
}

.CheckoutMain .card-body .card-payment-button {
  padding: 30px 0px 0px;
  box-sizing: border-box;
}

.CheckoutMain .card-body .card-payment-button button {
  width: 100%;
  height: 50px;
  border: 0;
  background: #3829e0;
  color: white;
  font-weight: 700;
  border-radius: 10px;
  background-image: linear-gradient(to right, #03d159, #00738E);
  /* box-shadow: 0px 10px 20px 0px hsl(141deg 92% 20% / 44%); */
  box-shadow: 0px 10px 20px 0px hsl(141deg 70% 20% / 27%);
  cursor: pointer;
}

.CheckoutMain .card-body .card-payment-button button:hover {
  opacity: 0.8;
  border: none;
  outline: none;
}

.CheckoutMain .card-body .card-payment-button button:focus {
  opacity: 0.8;
  border: none;
  outline: none;
}

.CheckoutMain .card-body .card-cancel-button {
  padding: 15px 0px;
  box-sizing: border-box;
}

.CheckoutMain .card-body .card-cancel-button button {
  width: 100%;
  height: 50px;
  border: 0;
  background: #3829e0;
  color: #00947c;
  font-weight: 700;
  border-radius: 10px;
  background: white;
  cursor: pointer;
  transition: all 0.3s
}

.CheckoutMain .card-body .card-cancel-button button:hover {
  background: #f4fffb;
  color: #00947c;
  transition: all 0.3s
}

.CheckoutMain .quantityCounter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CheckoutMain .quantityCounter .countBtn {
  height: 38px;
  width: 38px;
  background-image: linear-gradient(to right, #03d159, #00738E);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CheckoutMain .quantityCounter .countBtn img {
  width: 20px;
}

.siepayFeatureSec {
  padding: 50px 0;
}

.siepayFeatureSec .row1 {
  display: flex;
  justify-content: space-between;
}

.siepayFeatureSec .row1 .col1 {
  background: #FAFAFA;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-height: 300px;
  margin-bottom: 25px;
}

.siepayFeatureSec .row1 .col2 {
  background: #FAFAFA;
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  width: 45%;
  display: flex;
  align-items: center;
  max-height: 300px;
  margin-bottom: 25px;
}

.siepayFeatureSec .row1 .col1 .fCard {
  padding: 40px 50px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.siepayFeatureSec .row1 .col1 .fCard h2 {
  color: #001939;
  font-size: 34px;
  max-width: 230px;
  margin-bottom: 0;
}

.siepayFeatureSec .row1 .col2 .fCard {
  padding: 40px 50px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}

.siepayFeatureSec .row1 .col2 .fCard h2 {
  color: #001939;
  font-size: 34px;
  max-width: 230px;
  margin-bottom: 0;
}

.siepayFeatureSec .row1 .imgDiv {
  width: 170px;
  margin-right: 20px;
}

.siepayFeatureSec .row1 .imgDiv img {
  max-width: 100%;
}

.privacyMain a {
  color: #00915d;
}

.cursor-pointer {
  cursor: pointer;
}


@media screen and (max-width: 768px) and (min-width: 200px) {

  .footermain {
    display: block;
    text-align: center;
  }

  .company {
    margin-top: 30px;
  }


  .support {
    margin-top: 0px;
  }

}

.dashboard {
  /* padding-top: 50px; */
  padding-bottom: 50px;
  min-height: calc(100vh - 329px);
}

.dashboard1 {
  background-color: #efefef;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 20px;
  margin-top: 50px;
  box-shadow: 0px 0px 14px -2px rgba(0, 0, 0, 0.75);
}


.activeDashButton {
  background: white;
  color: #08B882;
  padding: 12px 18px;
  border-radius: 10px;
  border: none;
  margin-right: 8px;
  font-size: 18px;
  margin-bottom: 25px;
  outline: none !important;
}

.inactiveDashButton {
  background: rgba(58, 58, 58, 0);
  color: #252525;
  padding: 12px 18px;
  border-radius: 10px;
  border: none;
  margin-right: 8px;
  outline: none !important;
  font-size: 18px;
  margin-bottom: 25px;
}

.left {
  margin-right: 12px;
}




@media screen and (min-width: 992px) and (max-width: 1200px) {
  .siepayFeatureSec .row1 .imgDiv {
    width: 160px;
  }

  .siepayFeatureSec .row1 .col2 .fCard h2 {
    font-size: 32px;
    max-width: 210px;
  }

  .siepayFeatureSec .row1 .col1 .fCard h2 {
    max-width: 210px;
    font-size: 32px;
  }

  .firstdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 15px;
    width: 200px;
  }

  .lastdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 20px;

    width: 200px;
  }

  .secdiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 15px;
    width: 420px;
  }

  .thirddiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 15px;
    width: 420px;
  }

  .sigup {
    text-align: center;
    margin-top: 10px;
  }

}

/* 
  
@media screen and (min-width: 768px) and (max-width: 992px){

  .firstdiv{
    padding-left: 10px;
      border-radius: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      outline: 1px solid grey;
      border: none;
      margin-left: 15px;
      width: 140px;
      font-size: 12px;
  }
  .lastdiv{
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 20px;
    
      width: 140px;
      font-size: 12px;
  }
  .secdiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 15px;
    width: 300px;
}
.thirddiv {
  margin-top: 30px;
  padding-left: 10px;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: 1px solid grey;
  border: none;
  margin-left: 15px;
  width: 300px;
}
.sigup {
  text-align: center;
  margin-top: 10px;
}
  
} */

.flex {
  display: flex;
}

.sec5 {
  padding: 40px 0;
}

.sec5 .row1 {
  position: relative;
  max-width: 900px;
  margin: auto;
  padding: 45px 0;
}

.sec5 .row1 .imgCard {
  background: #FAFAFA;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec5 .row1 .imgCard .image1 {
  max-width: 100%;
  max-height: 160px;
}

.sec5 .row1 .imgCard .image2 {
  max-width: 100%;
  max-height: 160px;
}

.sec5 .row1 .imgCard .image3 {
  max-width: 100%;
  max-height: 160px;
}

.sec5 .row1 .imgCard .image4 {
  max-width: 100%;
  max-height: 160px;
}

.sec5 .row1 .contentCard {
  padding: 30px;
}

.sec5 .row1 .contentCard h1 {
  color: #001939;
  font-weight: bold;
}

.sec5 .row1 .contentCard h6 {
  font-size: 25px;
  color: #001939;
  margin-bottom: 2px;
}

.sec5 .row1 .contentCard p {
  margin: 0;
  font-size: 18px;
  color: #3D3C3C;
}

.sec5 .row1 .effect1 {
  position: absolute;
  width: 68%;
  left: 50%;
  transform: translate(-50%, 0);
}

.text-right {
  text-align: right;
}

.h21223 {
  margin-top: 100px;
  color: #001939;
}

.introChar {
  width: 88%;
  position: absolute;
  left: 53px;
}

.video-react .video-react-big-play-button {
  display: none !important;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .siepayFeatureSec .row1 .imgDiv {
    width: 120px;
    margin-right: 10px;
  }

  .siepayFeatureSec .row1 .col2 .fCard h2 {
    font-size: 27px;
    max-width: 160px;
  }

  .siepayFeatureSec .row1 .col1 .fCard h2 {
    max-width: 160px;
    font-size: 27px;
  }

  .siepayFeatureSec .row1 .col2 .fCard {
    padding-left: 30px;
  }

  .siepayFeatureSec .row1 .col1 .fCard {
    padding-right: 30px;
  }


  .CheckoutMain .headingMain h1 {
    font-size: 60px;
  }

  .dollar {
    width: 15%;
    padding-right: 12px;
    padding-top: 2px;
  }

  .flex {
    display: block;
  }

  .sectop {
    margin-top: 71px;
    text-align: center;
  }


  .firstdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: 340px;
    font-size: 12px;
  }

  .lastdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 20px;

    width: 330px;
    font-size: 12px;
  }

  .secdiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .thirddiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .sigup {
    text-align: center;
    margin-top: 24px;
  }

  #smallcenter {
    text-align: center;
  }

  .create {
    background-color: white;
    height: auto;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

}


@media screen and (min-width: 576px) and (max-width: 767px) {
  .dollar {
    width: 10%;
    padding-right: 12px;
    padding-top: 2px;
  }

  .flex {
    display: block;
  }

  .sectop {
    margin-top: 71px;
    text-align: center;
  }

  .firstdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: 240px;
    font-size: 12px;
  }

  .lastdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 20px;

    width: 250px;
    font-size: 12px;
  }

  .secdiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .thirddiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .sigup {
    text-align: center;
    margin-top: 24px;
  }

  #smallcenter {
    text-align: center;
  }

  .create {
    background-color: white;
    height: auto;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .h21223 {
    margin-top: 100px;
    /* font-size: 25px; */
  }

}

.flex2 {
  display: flex;
}

@media screen and (min-width: 200px) and (max-width: 576px) {
  .flex2 {
    display: block;
  }

  .dollar {
    width: 20%;
    padding-right: 12px;
    padding-top: 2px;
  }

  .flex {
    display: block;
  }

  .sectop {
    margin-top: 117px;
    text-align: center;
  }

  .firstdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
    font-size: 12px;
    margin-top: 30px;
  }

  .lastdiv {
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    margin-top: 30px;

    width: -webkit-fill-available;
    font-size: 12px;
  }

  .secdiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .thirddiv {
    margin-top: 30px;
    padding-left: 10px;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: 1px solid grey;
    border: none;
    margin-left: 0px;
    width: -webkit-fill-available;
  }

  .sigup {
    text-align: center;
    margin-top: 24px;
  }

  #smallcenter {
    text-align: center;
  }

  .create {
    background-color: white;
    height: auto;
    padding-bottom: 20px;
    width: 100%;
    border-radius: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }

}

@media screen and (min-width: 200px) and (max-width: 992px) {
  .sectop1 {
    margin-top: 71px;
    text-align: center;
  }

  .introChar {
    left: 39px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .eye {
    margin-top: -66px;
    margin-left: 395px;
  }

  .introChar {
    left: 47px;
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {

  .create1 {
    background-color: white;
    height: auto;
    padding-bottom: 20px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .eye {
    margin-top: -66px;
    margin-left: 655px;
  }
}

@media screen and (max-width: 768px) {
  .CheckoutMain .effect {
    position: absolute;
    top: 0;
    /* height: 306px; */
    min-height: 238px;
    height: 28vh;
    width: 100%;
    background-image: linear-gradient(to bottom, #03d159, #00738E);
    clip-path: polygon(0% 0%, 100% 0%, 100% 83%, 0% 100%);
    /* min-height: auto; */
  }

  .CheckoutMain .headingMain {
    margin-bottom: 40px;
  }

  .CheckoutMain .headingMain h1 {
    font-size: 60px;
  }

  .introChar {
    left: 33px;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .siepayFeatureSec .row1 .imgDiv {
    width: 100px;
    margin-right: 0px;
  }

  .siepayFeatureSec .row1 .col2 .fCard h2 {
    font-size: 20px;
    max-width: 140px;
  }

  .siepayFeatureSec .row1 .col1 .fCard h2 {
    font-size: 20px;
    max-width: 140px;
  }

  .siepayFeatureSec .row1 .col2 .fCard {
    padding: 30px;
    padding-left: 30px;
  }

  .siepayFeatureSec .row1 .col1 .fCard {
    padding: 30px;
    padding-right: 30px;
  }

  .siepayFeatureSec .row1 .col2 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .siepayFeatureSec .row1 .col1 {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .sec5 .row1 .contentCard {
    padding: 0;
  }

  .sec5 .row1 .contentCard h1 {
    font-size: 35px;
  }

  .sec5 .row1 .contentCard h6 {
    font-size: 22px;
  }

  .sec5 .row1 .contentCard p {
    font-size: 16px;
  }

  .sec5 .row1 {
    padding: 30px 0;
  }

  .create1 {
    background-color: white;
    height: 600px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .eye {
    margin-top: -66px;
    margin-left: 476px;
  }
}

.flex3 {
  display: flex;
}

button:focus {
  outline: none;
}


@media screen and (min-width: 200px) and (max-width: 576px) {
  .siepayFeatureSec .row1 .imgDiv {
    width: 70px;
    margin-right: 0px;
  }

  .siepayFeatureSec .row1 .col2 .fCard h2 {
    font-size: 16px;
    max-width: 90px;
  }

  .siepayFeatureSec .row1 .col1 .fCard h2 {
    font-size: 16px;
    max-width: 90px;
  }

  .siepayFeatureSec .row1 .col2 .fCard {
    padding: 25px;
    padding-left: 0px;
    padding-right: 10px;
  }

  .siepayFeatureSec .row1 .col1 .fCard {
    padding: 25px;
    padding-left: 0px;
    padding-right: 10px;
  }

  .siepayFeatureSec .row1 .col2 {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    width: 49%;
    justify-content: center;
  }

  .siepayFeatureSec .row1 .col1 {
    width: 49%;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    justify-content: center;
  }

  .sec5 .row1 .contentCard {
    padding: 0;
  }

  .sec5 .row1 .contentCard h1 {
    font-size: 35px;
  }

  .sec5 .row1 .contentCard h6 {
    font-size: 22px;
  }

  .sec5 .row1 .contentCard p {
    font-size: 16px;
  }

  .sec5 .row1 {
    padding: 16px 0;
  }

  .crebtn1 {
    color: white;
    background: linear-gradient(90deg, #05EC65 -52.32%, rgb(0, 115, 142) 100%);
    text-align: -webkit-center;
    justify-content: center;
    padding: 10px 31px;
    border: none;
    border-radius: 16px;
    margin-left: 0px;
    margin-top: 15px;
  }

  .flex3 {
    display: block;
  }

  .center {
    text-align: center;
  }

  .create1 {
    background-color: white;
    height: auto;
    padding-bottom: 20px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;

  }

  .eye {
    margin-top: -66px;
    margin-left: 400px;
    display: none;
  }
}

@media screen and (min-width: 576px) {
  .privacyModal .modal-dialog {
    max-width: 85%;
  }
}

@media screen and (min-width: 200px) and (max-width: 576px) {
  .jc-fe-Sm {
    justify-content: flex-end;
  }

  .mosth1 {
    font-size: 45px;
  }

  .sec8 {
    /* margin-top: -111px; */
    /* margin-bottom: 90px; */
  }

  .introChar {
    left: 25%;
  }
}

.display {
  display: flex;
  justify-content: center;
}


@media  screen and (min-width: 200px) and (max-width: 450px) {
  .introChar {
    left: 28% !important;
  }
}

@media screen and (max-width: 350px) {
  .siepayFeatureSec .row1 .imgDiv {
    width: 55px;
  }

  .siepayFeatureSec .row1 .col2 .fCard h2 {
    font-size: 14px;
  }

  .siepayFeatureSec .row1 .col1 .fCard h2 {
    font-size: 14px;
  }

  .introChar {
    left: 35% !important;
  }
}

@media screen and (min-width: 200px) and (max-width: 768px) {

  .display {
    display: block;
    justify-content: center;
    text-align: center;
  }

}

.profile {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  flex-direction: column !important;
  padding: 10px;
}

.profile label {
  width: 100%;
  font-weight: 500;
}

.profile input {
  width: 100%;
  border: none;
  background-color: #ebebebe5;
  margin-bottom: 10px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
}

.profile button {
  background-image: linear-gradient(to right, #05EC65, #00738E);
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
  width: 60%;
  border: none;
  margin-top: 10px;
}

.d-flex {
  display: flex;
}

.gloabalModal {
  position: fixed;
  overflow: auto;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 1111;
}

.gloabalModal .modal_visible {
  width: 100%;
  max-width: 500px;
  padding: 40px 8px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 20px;
  position: relative;
}

.globalLoader {
  position: fixed;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 1111;
}

.globalLoader .loader {
  width: 100%;
  max-width: 300px;
  padding: 40px 0;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 20px;
  position: fixed;
}

.globalLoader .msg {
  color: black;
  font-size: 22px;
  font-weight: 600;
}

.globalLoader h3 {
  text-align: center;
  margin-top: 20px;
  margin-left: 30px;
  color: black;
}

.globalLoader img {
  width: 90px;
  margin-right: 20px;
  animation: beat .4s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.3);
  }
}

.gloabalModal {
  position: fixed;
  overflow: auto;
  top: 0;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  width: 100vw;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, .2);
  z-index: 1111;
}

.gloabalModal .modal_visible {
  width: 100%;
  max-width: 500px;
  padding: 40px 8px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #fff;
  border-radius: 20px;
  position: relative;
}

.gloabalModal .modal_visible span {
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 700;
  cursor: pointer;
}

.twofa-modal div {
  margin-bottom: 10px;
}

.twofa-modal div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.twofa-modal div label {
  font-weight: 600;
}

.twofa-modal div input {
  width: 60%;
  border: none;
  background-color: #ebebebe5;
  margin-bottom: 10px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
}

.twofa-modal div button {
  background-image: linear-gradient(to right, #05EC65, #00738E);
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 60%;
  border: none;
  margin-top: 10px;
}

.table-con {
  position: relative;
}

.addIcon {
  position: absolute;
  right: 0;
  top: 10px;
  background-color: rgb(182, 180, 180);
  padding: 5px;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 1.1em;
  cursor: pointer;
  transition: all .5s;
}

.addIcon:hover {
  background-color: #dfdfdf;
}

.table-con-ico th {
  padding: 15px;
}

.product-modal {
  max-height: 90vh;
  min-width: 600px;
  overflow: auto;
}

.product-modal div {
  margin-bottom: 10px;
}

.product-modal div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.product-modal div label {
  font-weight: 600;
}

.product-modal div input {
  width: 80%;
  border: none;
  background-color: #ebebebe5;
  margin-bottom: 10px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
}

.product-modal div select {
  width: 80%;
  border: none;
  background-color: #ebebebe5;
  margin-bottom: 10px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
}

.product-modal div button {
  background-image: linear-gradient(to right, #05EC65, #00738E);
  color: white;
  padding: 10px;
  border-radius: 10px;
  width: 60%;
  border: none;
  margin-top: 10px;
}

.icon-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.icon-actions div {
  cursor: pointer;
  margin-right: 10px;
}

.icon-actions div img {
  width: 40px;
  background-color: #ebebebe5;
  transition: all .5s;
  padding: 10px;
  border-radius: 10px;
}

.icon-actions div img:hover {
  background-color: #d3d3d3e5;
}

.share-modal-product .icons-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.share-modal-product div img {
  width: 50px;
  background-color: #cfcfcfe5;
  margin-right: 10px;
  transition: all .5s;
  padding: 10px;
  border-radius: 10px;
  transition: all .5s;
}

.share-modal-product div img:hover {
  background-color: #e7e6e6e5;
  cursor: pointer;
}

.transaction-modal-product {
  min-width: 70vw;
  max-height: 650px;
  overflow: auto;
}
.wcm-overlay {
  z-index: 11111 !important;
}
.wcm-active {
  z-index: 11111 !important;
}
#wcm-modal {
  z-index: 11111 !important;
}