.mDetailMenu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.mDetailMenu .roundButton {
    max-width: 134px;
    width: 100%;
}
.subscripPage .idImg {
    width: 150px;
    max-height: 150px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.subscripPage .idImg img {
    max-width: 100%;
    max-height: 100%;
}
.subscripPage .editKYCIco img{
    padding: 6px;
    background-color: black;
    border-radius: 7px;
    cursor: pointer;

}