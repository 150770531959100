.subscripPage  {
    color: #0F1F3B;
}
.subscripPage .row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gradBtn {
    background: linear-gradient(-45deg, #00728C 0%,#08B882 60%, #04EC65 100%);
    border: none;
    color: white;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 8px;
    padding: 12px 25px;
}
.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-14 {
    font-size: 14px;
}
.fs-15 {
    font-size: 15px;
}
.fs-16 {
    font-size: 16px;
}
.subscripPage .icons-cont {
    display: flex;align-items: center;
}
.subscripPage .editIco img {
    filter: invert(1);
    cursor: pointer;
}
.subscripPage .icons-cont img{
    max-height: 25px;
    cursor: pointer;
}
.subscripPage .copyLink{
    position: relative;
    cursor: pointer;
}
.subscripPage .copyLink .copyText{
    position: relative;
    background: #000;
    border-radius: 6px;
    color: #fff;
    display: none;
    font-weight: 400;
    left: 50%;
    padding: 4px 10px;
    position: absolute;
    top: -17px;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: -webkit-max-content;
    width: max-content;
}
.subscripPage .copyLink:hover .copyText{
display: block;
}


.subscripPage .Icoimg{
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
}
.subscripPage .Icoimg img{
    max-width: 25px;
    max-height: 25px;
}
.subscripPage .eventBadge {
    min-width: 90.8px;
}