.productModal {
    position: relative;
    padding: 30px;
}

.modal-content {
    border-radius: 20px;
}

.productModal .closeIco {
    position: absolute;
    top: 0px;
    right: 5px;
    cursor: pointer;
}

.productModal .logoRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.productModal .form-group {
    margin-bottom: 20px;
}

.productModal label {
    color: #0F1F3B;
    font-weight: 500;
    font-size: 14px;
}

.productModal .form-group input {
    border-radius: 8px;
    border-color: #EAEAEA;
    font-size: 14px;
    color: #000;
}

.productModal .form-group input::placeholder {
    color: #CACACA;
}

.productModal .uploadFile {
    width: 100%;
}

.productModal .uploadFile .title {
    font-size: 13px;
}

.productModal .uploadFile input[type="file"] {
    display: none;
}

.productModal .uploadFile label {
    cursor: pointer;
    width: 100%;
    height: 150px;
    border: 1px dashed #a5b4c7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

}

.productModal .uploadFile label img {
    max-width: 100%;
    max-height: 100%;
}

.productModal .expenseDown .downImg {
    filter: invert(1);
}

.productModal .expenseDown {
    display: flex;
    align-items: center;
    padding: 8px;
    height: 37px;
    font-size: 14px;
    background: #08B882;
    color: white;
    border-radius: 8px;
    margin-left: 15px;
    text-decoration: none;
}

.productModal .form-group .Icoimg {
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 10px;
}

.productModal .form-group .Icoimg img {
    max-width: 25px;
    max-height: 25px;
}

.productModal .dropSelect {
    display: flex;
    align-items: center;
    padding: 8px;
    height: 37px;
    font-size: 14px;
    color: #CACACA;
    border: 1px solid #EAEAEA;
    border-radius: 8px;
    text-decoration: none;
}

.tt-capitalize {
    text-transform: capitalize;
}

.productModal .sPaymentM {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 23px 0;
    margin: 20px 0;
    border-top: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
}

.productModal .dropdown .dropdown-menu {
    transform: translate3d(0px, 37px, 0px);
    left: 0px;
    width: 100%;
}

.productModal .sPaymentM .anual {
    font-size: 12px;
    color: #CACACA;
    display: flex;
    align-items: center;
}

.productModal .sPaymentM .anual input {
    width: 10px;
}

.productModal .form-group .profile-div {
    text-transform: capitalize;
    color: #000;
}

.productModal .form-group .dropdown-item {
    text-transform: capitalize;
}

.optional {
    text-transform: uppercase;
    font-size: 10px;
    color: #CACACA;
}

.limitedAddress {
    max-width: 74px;
    display: inline-block;
    overflow: hidden;
}




/* ------------------------- Musto Updates ------------------------- */
.m-pi-header .pi-filters {
    display: flex;
    margin: 30px 0px;
    cursor: pointer;
}

.m-pi-header .pi-filters .filter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 40px;
    border: none;
    margin: 0px 10px;
    margin-left: 0px;
    border-radius: 20px;
    border: 1px solid #7070703b;
    background-color: #ffffff;
    color: #CACACA;
}

.m-pi-header .pi-filters .filter.active {
    background: linear-gradient(-45deg, #00728C 0%, #08B882 60%, #04EC65 100%);
    font-weight: 500;
    border: none;
    color: white;
}

.m-pi-data .card-header {
    padding: 0% 0% 20px 0% !important;
}

.m-pi-table {
    /* background-color: #00728C; */
}

.m-pi-table thead {
    background: linear-gradient(-45deg, #00728C 0%, #08B882 60%, #04EC65 100%) !important;
}

.m-pi-table th:first-child,
.m-pi-table th:last-child,
.m-pi-table th {
    border: none !important;
    border-radius: 0px !important;
    color: white !important;
    padding: 20px 5px;
    font-weight: normal !important;
}

.m-pi-table td .price {
    color: #08B882 !important;
    border: 1.5px solid #08B882 !important;
    border-radius: 7px;
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F9F9F9;
}

.m-add-product-head {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}

.m-product-modal-tab {
    padding: 8px 15px;
    margin: 0px 6px;
    font-weight: 600;
    color: #CACACA;
    border-bottom: 2px solid #CACACA;
    position: relative;
    cursor: pointer;
}

.m-product-modal-tab.active {
    transition: .5s;
    color: #08B882;
    border-bottom: 2px solid #08B882;
}

.m-product-modal-tab::after {
    transition: .5s;
    position: absolute;
    left: 0%;
    bottom: -2px;
    content: "";
    color: #08B882;
    width: 0%;
    border-bottom: 2px solid #08B882;
}

.m-product-modal-tab:hover::after {
    transition: .5s;
    width: 100%;

}

.m-signin-form .form-input.modalInput input {
    max-width: 100% !important;
}


.m-signin-form.productModal .dropSelect {
    background-color: #EAEEF2;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
}

.m-signin-form.productModal .dropdown-menu {
    min-width: 9rem;
    transform: translate3d(-17px, 37px, 0px);
}



.m-product-modal {
    position: relative;
}

.m-product-modal .closeIco {
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.modalInput .checkBoxModal {
    display: flex;
    border: 1px solid #E1E5E9;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
}

.modalInput .checkBoxModal p {
    margin: 0;
    color: #707070;
    font-size: 12px;
}

.modalInput .checkBoxModal .opiop {
    background: #CACACA;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 50%;
}

.modalInput .checkBoxModal .opiop img {
    width: 12px;
}


.modalInput .checkBoxModal.active {
    border: 1px solid #08B882;
}

.modalInput .checkBoxModal.active .opiop {
    background-color: #08B882;
}


@media screen and (max-width: 375px) {
    .m-product-modal .closeIco {
        position: absolute;
        right: -5px;
        top: -5px;
    }
}