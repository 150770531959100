.profilePage{
    padding: 30px;
    border-radius: 12px;
    background: white;
    margin-bottom: 20px;
}
.profilePage .row1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profilePage h1 {
    color: #0F1F3B;
    font-size: 24px;
}
.transparentBtn {
    background: transparent;
    border: 2px solid #08B882;
    color: #08B882;
    font-size: 13px;
    font-weight: 500;
    padding: 6px 10px;
    border-radius: 8px;
    padding: 10px 25px;
    max-width: 170px;
    width: 100%;
}
.profilePage .formMain {
    max-width: 840px;
}
.profilePage .formMain h6 {
    font-weight: bold;
}
.profilePage .formRow {
    display: flex;
}
.profilePage .formMain input {
    border-radius: 8px;
    border-color: #EAEAEA;
    font-size: 16px;
    color: #000000;
    height: 50px;
}
.profilePage .formMain input::placeholder {
    color: #CACACA;
}
.profilePage .formMain .form-group {
    width: 100%;
}
.profilePage .formMain .formRow .form-group:first-child {
    margin-right: 20px;
}
.profilePage .formMain label {
    font-weight: bold;
    font-size: 14px;
}
.profilePage .formMain .inputDiv {
    position: relative;
}
.profilePage .formMain .inputDiv .iconRight{
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
}
.profilePage .formMain .inputDiv .iconLeft{
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
}
