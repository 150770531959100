@font-face {
    font-family: poppinsExtraBold;
    src: url(../../assets/font/Poppins/Poppins-Bold.ttf);
}
.protocolPage {
    font-family: poppins;
    color: #001939;
}
.protocolPage .section1{
    padding-top: 110px;
    min-height: 100vh;
    display: flex;
    align-items: center;
}
.protocolPage .section1 h1 {
    font-size: 45px;
    font-family: poppinsBold;
    margin-bottom: 25px;
}
.protocolPage .section1 p {
    font-size: 18px;
}
.protocolPage .section2 {
    padding: 70px 0;
}
.protocolPage .section2 .cardM {
    background: #F6F6F6;
    border-radius: 18px;
    padding: 30px 25px;
    min-height: 173.6px;
    height: 100%;
}
.protocolPage .section2 .cardM h3 {
    font-size: 28px;
}
.protocolPage .section2 .cardM p {
    margin-bottom: 0;
}
.protocolPage .section3 .desc {
    margin: auto;
    margin-bottom: 50px;
    font-size: 18px;
    max-width: 800px;
    text-align: center;
}
.protocolPage .section3 .cardM {
    background: #F6F6F6;
    border-radius: 18px;
    padding: 30px 25px;
    /* margin-bottom: 30px; */
    min-height: 173.6px;
    height: 100%;
}
.protocolPage .section3 .cardM h3 {
    font-size: 22px;
}
.protocolPage .section3 .cardM p {
    margin-bottom: 0;
}
.protocolPage .section3 .number {
    color: #08B882;
    font-size: 95px;
    font-family: poppinsExtraBold;
}
.dib {
    display: inline-block;
}