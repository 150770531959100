.settingPage .h3 {
    color: #0F1F3B;
    font-size: 24px;
}
.settingPage .sRowM {
    margin-bottom: 30px;
}
.settingPage .sRowM h6{
    font-weight: bold;
    color: #0F1F3B;
}
.settingPage .sRowM p{
    font-size: 15px;
    color: #9d9d9d;
}
.settingPage .sRow {
    display: flex;
    justify-content: space-between;
}

.settingPage .sRow .toggleBtn{
    width: 51px;
    height: 30px;
    background: #D4D4D4;
    border-radius: 30px;
    padding: 2px;
    transition:all 0.3s
}
.settingPage .sRow .toggleBtn .circle{
    width: 26px;
    height: 26px;
    background: white;
    border-radius: 50%;
    transition:all 0.3s;
    margin-top: 0;
    border: none;
}
.ml-20 {
    margin-left: 20px;
}
.settingPage .sRow .bgToggle {
    background: #04A55E !important;
}
.ml-18 {
    margin-left: 18px;
}