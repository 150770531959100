@font-face {
  font-family: PoppinsBold;
  src: url(./assets/font/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: Poppins;
  src: url(./assets/font/Poppins/Poppins-Regular.ttf);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #EAEEF2 !important;   */
  /* background-color: #EAEEF2 !important;   */
}
.homePage {
  overflow: hidden;
}
.homePage h1, .homePage h2, .homePage h3, .homePage h4, .homePage h5 ,.homePage h6 {
  font-family: PoppinsBold;
  font-weight: bold;
}
.homePage p {
  font-family: Poppins;
}
.homePage .navbar-brand {
  position: relative;
  top: -9px;
}
.heapayment{
  font-size: 42px;
  color: #001939;
  margin-bottom: 20px;
  max-width: 520px;
  line-height: 1.3;
}
.heapayment span{
  color: #08B882;
}
.ppayment{
  font-size: 17px;
  max-width: 480px;
  color: #3D3C3C;
}
.homePage .navbar  {
  position: fixed;
  top: 0;
  width: 100%;
  /* backdrop-filter: blur(18px); */
  z-index: 5;
  transition: all 0.3s;
}

.bb-green {
  border-bottom: 2px solid #00c77f;
}
.navbackdrop {
  backdrop-filter: blur(18px) brightness(0.9);
}
.homePage .nav-link {
  transition: all 0.3s;
  /* padding: 8px 14px !important; */
  margin: 0 7px;
  background: linear-gradient(270deg,#05EC65 0,#08B882 ,#00738e) 0 100% transparent no-repeat;
  background-size: 0% 2px;
  font-family: poppins;
}
.homePage .nav-link:hover {
  /* transform: scale(1.2); */
  background: linear-gradient(270deg,#05EC65 0,#08B882 ,#00738e) 0 100% transparent no-repeat;
  background-size: 100% 2px;
  transition: all 0.3s;
}
.homePage .demoSec {

  background: url(./images/demoBg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  margin-top: 100px;
}
.homePage .demoSec .demoMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}
.homePage .demoSec .demoMain .heading {
  font-family: poppinsBold;
  color: #fff;
  font-size: 55px;
  margin-bottom: 10px;
}
.homePage .demoSec .demoMain .desc {
  font-family: poppins;
  font-size: 17px;
  margin-bottom: 0;
  color: #fff;
}
.homePage .demoSec .demoMain .demoBtn:hover {
  transition: all 0.3s;
  transform: scale(1.1);
  text-decoration: none;
}
.homePage .demoSec .demoMain .demoBtn {
  transition: all 0.3s;
  font-family: poppins;
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  width: 200px;
  color: #08B882;
  border: none;
  font-weight: 500;
  text-align: center;
}


.sec1{
  padding-top: 110px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  /* overflow: hidden; */
}
.sec1 .sec1Effect1 {
  position: absolute;
  right: -70px;
  width: 79%;
  top: -61px;
  z-index: -1;
}
.sec1 .ellipse1 {
  position: absolute;
  right: -2%;
  width: 17%;
  bottom: 17%;
  z-index: -1;
}
.sec1 .dashboard1Img {
  box-shadow: 0px 3px 13px 0px rgba(0,0,0,0.15);
  border-radius: 20px;
  overflow: hidden;
}
.sec1 .dashboard1Img {
  max-width: 100%;
  position: relative;
  width: 85%;
  float: right;
}
.sec1 .mobile1Img {
  display: inline-block;
  max-width: 100%;
  position: relative;
  width: 38%;
  top: -48%;
  border-radius: 15px;
  box-shadow: 0px 3px 13px 0px rgba(0,0,0,0.15);
  border-radius: 20px;
  overflow: hidden;
}
.sec1 .counter {
  font-size: 60px;
  position: absolute;
  font-weight: bold;
  color: #08B882;
}
.sec1 .imgMain {
  max-height: 430px;
}
.slick-prev, .slick-next{
  display: none !important;
}
.slick-dots li button {
  display: none !important;
}

.features-sect {
  margin-bottom: 30px;
}


/* #05EC65 ... #00738E */
.btn1{
  font-family: poppins;
  background: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%) 0 100% transparent no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  padding: 10px 28px;
  color: white;
  border: none;
  /* border: 2px solid transparent; */

/* background-image: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%); */
  border-radius: 10px;
    padding: 10px 28px;
    color: white;
  /* border: none; */
  border: 2px solid white;
  height: 44px;
  min-width: 139px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:all 0.3s
  
}
.btn1:hover{
  
  background: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%) 0 100% transparent no-repeat;
  background-color: white;
  background-size: 0% 100%;
  border-radius: 10px;
  border: 2px solid #05EC65;
  color: #00a787;
  transition:all 0.3s;
  text-decoration: none;
}
.btn1:focus {
  box-shadow: none !important;
}
.transparentBtnDark{
  font-family: poppins;
  border-radius: 10px;
  padding: 8px;
  border: none;
  /* max-width: 140px; */
  width: 140px;
  text-align: center;
  /* border: 2px solid #001939;
  background: white;
  color: #001939; */
  border: 2px solid #05EC65;
  background: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%) 0 100% transparent no-repeat;
  background-size: 0% 100%;
  background-color: white;
  color: #00a787;
  transition:all 0.3s
  
}
.transparentBtnDark:hover{
  background: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%) 0 100% transparent no-repeat;
  background-size: 100% 100%;
  /* border: none; */
  border: 2px solid white;
  /* background-image: linear-gradient(to right, #05EC65 0%, #08B882 27%,#00738e 200%); */
  color: white;
  transition:all 0.3s;
  /* border: none; */
  /* outline: 1px solid #00738E; */
  text-decoration: none;
  
}

.lmore{

  font-size: 16px;
  margin-left: 80px;
  background: transparent;
  border: 1px solid transparent;
  padding: 10px 28px;
  transition: all 0.3s;
  border-radius: 10px;
}
.lmore:hover{

  background-image: linear-gradient(to right, #05EC65, #00738E);
    padding: 10px 28px;
    color: white;
    border: 1px solid #707070;
    transition: all 0.3s;
}
.btnsec{
  margin-top: 40px;
}


.sec2div{
  background: #00738E;
  border-radius: 26px;
  padding: 40px 58px;
}
.sec2div .imgMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sec2div .imgMain .logo1{
  max-height: 40px;
}
.sec2div .imgMain .logo2{
  max-height: 40px;
}
.sec2div .imgMain .logo3{
  max-height: 40px;
}
.collh1{
  font-size: 35px;
  color: #3D3C3C;
}
.sec2{
  margin-top: 90px;
}

.exchea{
 
  text-align: center;
  font-size: 44px;
  justify-content: center;
  margin-bottom: 109px;
}
.sec3{
  margin-top: 90px;
  padding-top: 50px;
  position: relative;
  /* background-color: #F2F2F2; */
}
.sec3 .effect {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -2;
  max-height: 1100px;
}
.btn2{
  margin-top: 30px;
  background: linear-gradient( #05EC65, #00738E),
             linear-gradient(to right, #05EC65, #00738E);
  border-radius: 10px;
  border-radius: 10px;
    padding: 10px 28px;
    color: white;
  border: 1px solid #707070;
  outline: 1px solid #707070;
  margin-left: 100px;

}
.btn2:hover{
  margin-top: 30px;

  margin-left: 100px;
  background: transparent;
  border-radius: 10px;
  border-radius: 10px;
    padding: 10px 28px;
    color: black;
  border: none;
  outline: 1px solid #00738E;

}

.img4{
  width: 100%;
}
.h1enable{
  color: #001939;
  font-size: 50px;
  /* margin-top: 90px; */
  /* margin-left: 100px; */
}
.pmerchant{
  margin-top: 30px;
  color: #3D3C3C;
  margin-bottom: 30px; 
  /* margin-left: 100px; */
}
.sec4{
  padding: 50px 0;
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.sec4 .noFee {
  color: #08B882;
  margin-top: 20px;
}
.sec4 .lFee{
  color: #001939;
}
.sec8{
 padding: 80px 0;
 padding-top: 160px;

}
.sec8 .faqMain {
  position: relative;
    padding: 0 30px;
}
.sec8 .faqMain .cardMain{
  position: relative;
    height: 475px;
    overflow: scroll;
    padding: 3px;
}
.sec8 .faqMain .cardMain .card-body {
  padding-top: 0;
}
.sec8 .faqMain .cardMain::-webkit-scrollbar {
  display: none;
}
.sec8 .faqMain button:focus{
  outline: none;
  box-shadow: none;
}
.sec8 .faqMain button{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  text-decoration: none;
  font-size: 19px;
  color: #000;
  font-weight: 500;
  background: white;
  padding-left: 0;
  padding-right: 0;
}
.sec8 .faqMain button span{
  font-weight: 500;
  font-size: 28px;

}

/* .sec8 .faqMain button .plus{
  display: inline-block;
}
.sec8 .faqMain button .minus{
  display: none;
}
.sec8 .faqMain button:not(.collapsed) .plus{
  display: none;
}
.sec8 .faqMain button:not(.collapsed) .minus{
  display: inline-block;
} */
.sec8 .faqMain button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "+";
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out;
}
.sec8 .faqMain button:not(.collapsed)::after {
  content: "-";

}
.sec8 .faqMain .card{
  border: none;
  margin-bottom: 12px;
  border-radius: 12px;
  border: 1px solid #DCDCDC;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 17%);
}
.sec8 .faqMain .card-header{
  background-color: white;
  border-radius: 12px;
  border: none;
}
.sec8 .faqMain .bgEffect{
  height: 400px;
  position: absolute;
  width: 100%;
  background: linear-gradient(223deg, #05EC65 0%, #08B882 27%,#00738e 100%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
}
.successPage {
  padding: 60px 0;
  padding-bottom: 0;
    /* min-height: 100vh; */
  display: flex;
  align-items: center;
}
.successPage .successCard {
  padding: 35px 33px;
  border-radius: 24px;
  text-align: center;
  background: white;
  text-align: center;
  box-shadow: 0px 3px 9px 1px rgb(0 0 0 / 17%);
  transition: all 0.3s;
  position: relative;
  top: 0;
  margin-bottom: 20px;
}
.successPage .successCard:hover { 
  box-shadow: 0px 8px 9px 1px rgb(0 0 0 / 17%);
  transition: all 0.3s;
  position: relative;
  top: -10px;
}
.successPage .successCard .imgDiv{
  width: 90px;
  height: 90px;
  margin: auto;
}
.successPage .successCard .imgDiv img{
  max-width: 100%;
  max-height: 100%;
}
.successPage .successCard h5{
  font-size: 22px;
  color: #08B882;
  margin: 15px 0 22px 0;
}
.successPage .successCard p{
  margin: 0;
  font-size: 17px;
  color: #001939;
}

.mosth1{
font-size: 45px;
color: #001939;
}
.pwant{
  margin-top: 30px;
  font-size: 18px;
  color: #3D3C3C;
}
.rectangle{
  width: 100%;
}

.siediv1{
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -274px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
}

.siediv2{
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -261px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
  margin-top: 3px;
}

.siediv3{
  margin-top: 14px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -261px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
}

.sec6{
  margin-top: 90px;
  /* background: linear-gradient( #05EC65, #00738E),
             linear-gradient(to right, #05EC65, #00738E); */
  background-color: #FAFAFA;
  padding: 50px 0;
}

.h1avail{
  color: white;
  font-size: 50px;
  color: #001939;
  margin-bottom: 20px;
}
.pwe{
  font-size: 16px;
  color: #001939;
}
.poffer{
  color: #001939;
  padding-top: 0px;
  font-size: 17px;
}

.availdiv{
  background-color: white;
    border: 9px solid black;
    border-radius: 24px;
    /* max-width: 112%; */
    height: 267px;
    margin-top: 106px;
    width: 294px;
}

.availdiv2{
  background-color: white;
  border: 5px solid black;
  border-radius: 18px;
  max-width: 26%;
  height: 183px;
  margin-top: 188px;
  margin-left: 34px;
}

.divlogo{
  width: 50%;
  justify-content: center;
  text-align: center;
  margin-top: 91px;
  margin-left: 67px;
}
.divlogo1{
  width: 40%;
    justify-content: center;
    text-align: center;
    margin-top: 60px;
    margin-left: 35px;
}

.h1what{
  text-align: center;
  justify-content: center;
}
.sec7{
  margin-top: 90px;
  margin-bottom: 140px;
}
.whatdiv1{
  background-image: linear-gradient(to right, #05EC65, #00738E);
    max-width: 350px;
    border: 2px solid beige;
    border-radius: 20px;
    /* padding: 38px 30px; */
    color: white;
    font-size: 17px;
    font-style: italic;
    height: 275px;
    padding-left: 25px;
    padding-right: 16px;
    padding-top: 29px;
    position: relative;
    top: 0;
    transition:all 0.3s
}

.whatdiv2{
  background-image: linear-gradient(to right, #05EC65, #00738E);
  max-width: 350px;
  border: 2px solid beige;
  border-radius: 20px;
  /* padding: 38px 30px; */
  color: white;
  font-size: 17px;
  font-style: italic;
  height: 275px;
  padding-left: 25px;
  padding-right: 16px;
  padding-top: 29px;
  position: relative;
  top: 0;
  transition:all 0.3s
}

.whatdiv1:hover{
  top: -10px;
  transition:all 0.3s
}
.whatdiv2:hover{
  top: -10px;
  transition:all 0.3s
}
.whatdiv3:hover{
  top: -10px;
  transition:all 0.3s
}
.whatdiv3{
  background-image: linear-gradient(to right, #05EC65, #00738E);
  max-width: 350px;
  border: 2px solid beige;
  border-radius: 20px;
  /* padding: 38px 30px; */
  color: white;
  font-size: 17px;
  font-style: italic;
  height: 275px;
  padding-left: 25px;
  padding-right: 16px;
  padding-top: 29px;
  position: relative;
  top: 0;
  transition:all 0.3s
}

.mainwhat{
  margin-top: 50px;
display: flex;
justify-content: space-between;
}

.viewmore{
  margin-top: 50px;
  text-align: center;
  justify-content: center;
}


.siepaydiv{
  background-image: linear-gradient(to bottom, #05EC65 0%, #08B882 27%,#00738e 100%);  border-radius: 50px;
  height: 160px;
  max-width: 116%;
  margin: 47px;
  
}

.siesecdiv{

  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: 236px;
  height: 149px;
  border-radius: 40px
}

.siediv11{
  background-color: white;
  text-align: center;
  justify-content: center;
  position: relative;
  top: -292px;
  border-radius: 20px;
  padding: 29px 40px;
  width: 200px;
  box-shadow: 0px 0px 14px -4px rgb(0 0 0 / 20%);
  transition: all 0.3s;
}
.siediv11:hover{
  background-color: white;
  text-align: center;
  justify-content: center;
  position: relative;
  top: -292px;
  border-radius: 20px;
  padding: 29px 40px;
  width: 200px;
  transform: scale(1.1);
z-index: 2;
transition: all 0.3s;
}









.psie{
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #001939;
}

.inh1{
  text-align: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 50px;
  font-weight: bold;
  color: #001939;
}

.inmain{
  display: flex;
  justify-content: space-between;
}

.inback{
  
  
    background: rgba(white,green,blue,5.6);
    transition: all 0.3s;

}

.inback:hover{
  
  
  background: rgba(white,green,blue,5.6);
  transform: scale(1.1);
  z-index: 2;
  transition: all 0.3s;


}
.inimg{
  text-align: center;

justify-content: space-between;
width: 300px;
border-radius: 10px;
}
.h5in{
  text-align: center;
  margin-top: 20px;
}
.pcrea{

  text-align: center;
  font-size: 14px;

}


#btn1header1{
  background-image: linear-gradient(to right, #05EC65, #00738E);
  color: white;
  padding: 6px 24px;
  border-radius: 10px;

}

#btn1header1:hover{
  background: transparent;
  color: black;
  border: 2px solid #05EC65;

}




@media screen and (max-width: 576px) {
  .homePage .demoSec .demoMain .heading {
    font-size: 35px;
  }
  .homePage .demoSec .demoMain .desc {
    font-size: 16px;
  }
  .btnsec {
    justify-content: center;
  }
  .btn1 {
    padding-left: 18px;
    padding-right: 18px;
  }
  .transparentBtnDark {
    padding-left: 18px;
    padding-right: 18px;
  }
  .sec8 .faqMain button {
    font-size: 15px;
  }
  .inh1 {
    font-size: 35px;
  }
  .h1enable {
    font-size: 35px;
  }
  .sec1 .imgMain {
    max-height: 458px;
  }
  .sec1 .mobile1Img {
    position: absolute;
    top: 30%;
  }
  .sec1 .counter {
    font-size: 46px;
  }
  .sec1 {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 992px) {



  .inmain{
    display: block;
    text-align: center;
  }

  .mainwhat{
    margin-top: 50px;
    display: block;
    /* justify-content: center; */
    text-align: -webkit-center;
  }
  .whatdiv2{
margin-top: 20px;
  }

  .whatdiv3{
    margin-top: 20px;
      }


      .siesecdiv{

        display: block;
        text-align: -webkit-center;

       
      }
      .siepaydiv {
        background-image: linear-gradient(to right, #05EC65, #00738E);
        border-radius: 50px;
        height: 645px;
        max-width: 150%;
        margin: 47px;
    }
    .siediv11{
      margin-top: 10px;
    }

    .siepaydiv{
      margin-bottom: 100px;
    }


.collh1{
  margin-bottom: 40px;
  text-align: center;
}
.heapayment{
  text-align: center;
}
.ppayment{
  text-align: center;
}
.h1enable {
  margin-top: 90px;
  margin-left: 0px;
}
.pmerchant {
  margin-top: 30px;
  margin-left: 0px;
}
.homePage .demoSec .demoMain {
  flex-direction: column;
}
.homePage .demoSec .demoMain .desc {
  margin-bottom: 20px;
}
}


@media screen and (max-width: 500px) and (min-width: 200px){
.firstimg{
  margin-top: 34px;
}
  .heapayment{
    font-size: 28px;
  }

}

@media screen and (max-width: 1200px) and (min-width: 992px){
  .vidRow .videoMa {
    height: 400px;
  }
  .successPage .successCard {
    padding: 30px 20px;
  }
  .sec1 .imgMain {
    max-height: 390px;
  }
  .sec1 .counter {
    font-size: 53px;
  }
  .sec8 .faqMain .cardMain {
    height: 522px;
  }
  .successPage .successCard h5 {
    font-size: 21px;
  }
  .rectangle {
    width: 100%;
    position: relative;
    top: 35px;
    height: 280px;
}
.sec1 .sec1Effect1 {
  right: -47px;
}
.sec1 .ellipse1 {
  bottom: 27%;
}


}


@media screen and (max-width: 992px) and (min-width: 768px){
  .vidRow .videoMa {
    height: 350px;
  }
  .sec8 .faqMain .cardMain {
    height: 450px;
  }
  .sec1 .imgMain {
    max-height: 575px;
  }
  .firstimg{
    margin-top: 34px;
  }

  .rectangle {
    width: 100%;
    position: relative;
    top: 10px;
    height: 280px;
}

  #margin{
    margin-top: 80px;
  }

}


@media screen and (max-width: 768px) and (min-width: 576px){
  .vidRow .videoMa {
    height: 220px;
  }
  .sec8 .faqMain .cardMain {
    height: 522px;
  }
  .sec1 .sec1Effect1 {
    top: -45px;
    width: 76%;
  }
}
@media screen and (max-width: 768px) and (min-width: 500px){
  .firstimg{
    margin-top: 34px;
  }
  .rectangle {
    width: 100%;
    position: relative;
    top: 33px;
    height: 280px;
}

#margin{
  margin-top: 27px;
}

}



@media screen and (max-width: 500px) and (min-width: 330px){
  .firstimg{
    margin-top: 34px;
  }
  .rectangle {
   display: none;
}

#margin{
  margin-top: 76px;
  background-image: linear-gradient(to right, #05EC65, #00738E);
  margin-bottom: 260px;
}
.mosmall{
  font-size: 18px;
}
.posmall{
  font-size: 20px;
}
.psmall{
  font-size: 16px;
}
.siediv3 {
  margin-top: 14px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 21px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
}
.siediv1 {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -25px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
}
.siediv2 {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
  margin-top: 3px;
}
}


@media screen and (max-width: 330px) and (min-width: 200px){

  .rectangle {
   display: none;
}

#margin{
  margin-top: 35px;
  background-image: linear-gradient(to right, #05EC65, #00738E);
  margin-bottom: 64px;
}
.mosmall{
  font-size: 14px;
}
.posmall{
  font-size: 16px;
}
.psmall{
  font-size: 12px;
}
.lmore {
  font-size: 16px;
  position: relative;
  top: 6px;
  left: -48px;
}
.siediv3 {
  margin-top: 14px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 21px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
}
.siediv1 {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -25px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
}
.siediv2 {
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 3px;
  max-width: 89%;
  left: 29px;
  padding: 10px 10px;
  margin-top: 3px;
}
}

#headerrnav{
  margin-left: 225px;
}

@media screen and (max-width: 992px) and (min-width: 200px){
  .sec1 .btnsec {
    margin-bottom: 70px;
  } 
#headerrnav {
  margin-left: 17px;
}

}

/* .sec9{
  background-color: #001939;
  color: white;
  padding-top: 73px;
  padding-bottom: 7px;
  
} */
.pfot{
  text-align: center;
  color: white;
  padding-top: 85px;
  padding-bottom: -4px;
  font-size: 14px;
}

.footermain h3{
  margin-bottom: 25px;
}
.footermain{
  display: flex;
  justify-content: space-between;
}
.footermain .cDetail p span{
  color: #fff;
}
.footermain .cDetail p {
  color: rgba(255,255,255,0.7);
  margin-bottom: 0;
  max-width: 400px;
  font-size: 14px;
}

.ffp{

  margin-top: 23px;
}
@media screen and (max-width: 768px){
  .sec2div {
    padding: 30px;
  }
}
@media screen and (max-width: 576px){
  .vidRow .videoMa {
    height: 250px;
  }
  .sec1 .sec1Effect1 {
    right: -9%;
    top: -20%;
  }
  .sec1 .ellipse1 {
    bottom: -10%;
  }
  .sec8 .faqMain .cardMain {
    height: 565px;
  }
  .sec2div .imgMain {
    flex-direction: column;
  }
  .sec2div .imgMain img{
    margin: 10px 0;
  }
  .sec8 .faqMain {
    padding: 0 16px;
  }
}
@media screen and (max-width: 768px) and (min-width: 200px){

  .footermain{
    display: block;
    text-align: center;
  }

  .company{
    margin-top: 30px;
  }
  

  .support{
    margin-top: 30px;
  }

}
#__react-alert__ div {
  z-index: 13000 !important;
}
    @media screen and (max-width: 400px){
      .vidRow .videoMa {
        height: 155px;
      }
    }
    @media screen and (max-width: 380px){
      .sec1 .counter{
        font-size: 38px;
      }
    }

