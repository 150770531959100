.m-signin-content {
    background-image: url("./images/updated-img/signIn-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.w-100 {
    width: 100%;
  }
.m-sign-text {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    max-width: 550px;
    margin-top: -75px;
}

.m-sign-text h1 {
    font-size: 60px;
}

.m-signin-sec2 {
    background: #fff;
    text-align: center;
    color: #202020;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.m-signin-sec2 p {
    color: #202020;
}

.m-signin-sec2 p span {
    color: #08B882;
}

.m-signin-form {
    max-width: 350px !important;
    margin: auto;
}

.m-signin-form .form-input input {
    max-width: 300px !important;
    margin-left: 0px;
    background-color: #EAEEF2;
    outline: none !important;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
}

.m-signin-form .m-signin-submit button {
    width: 100% !important;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
}

.m-signup-form {
    max-width: 600px !important;
    margin: auto;
}

.m-signup-form .form-input-2 {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.m-signup-form .form-input-2 input, .m-signup-form .form-input-2 select {
    width: 100% !important;
    margin-left: 0px;
    background-color: #EAEEF2;
    outline: none !important;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: 15px 0px 0px 0px;
}

.m-signup-form .m-signup-submit {
    width: 300px;
    margin: auto;
}

.m-signup-form .m-signup-submit button {
    width: 100% !important;
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
}

.m-forget {
    display: flex;
    justify-content: end;
    margin-right: 25px;
    margin-bottom: 20px;
}

.m-forget .forgot {
    font-family: Poppins;
    margin-top: 7px;
    color: #969696;
}


/* checkout */
.CheckoutMain .effect {
    background-image: none;
}

.m-checkout {
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: end;
}

.m-checkout-content {
    padding: 50px 2px 2px 2px;
    width: 90%;
    height: 90vh;
    position: absolute;
    right: 0px;
    bottom: 0px;
    border-top-left-radius: 50px;
    background-image: url("./images/updated-img/signIn-bg.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.m-checkout-content .m-order-summary {
    background: #fff;
    margin: 50px auto;
    padding: 20px;
    border-radius: 23px;
    max-width: 350px;
}

.m-checkout-content .m-order-summary .head {
    color: #202020;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10px;
}

.m-checkout-content .m-order-summary .title {
    color: #202020;
    margin-bottom: 0px;
}

.m-checkout-content .m-order-summary .asd {
    color: #202020;
    font-weight: 600;
    margin-bottom: 10px;
}

.m-checkout-content .m-order-summary .product {
    display: flex;
    justify-content: space-between;
    color: #202020;
    margin-bottom: 5px;
}

.m-checkout-content .m-order-summary .product .price {
    text-align: end;
    color: #202020;
}

.m-checkout-content .m-order-summary .total {
    display: flex;
    justify-content: space-between;
    color: #202020;
    margin-top: 20px;
}

.m-checkout-content .m-order-summary .total .price {
    text-align: end;
    color: #202020;
}

.m-checkout-content .m-order-summary .discount {
    display: flex;
    justify-content: space-between;
    color: #CACACA;
    margin-top: 20px;
}

.m-checkout-content .m-order-summary .discount .price {
    text-align: end;
    color: #CACACA;
}

.m-checkout-content .m-order-summary .quantityCounter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* checkout modal */
.checkout-modal .modal-header {
    border-bottom: 0px;
}

.checkout-modal .modal-header .modal-title {
    margin-top: 15px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    font-size: 36px;
    color: #0F1F3B;
}

.checkout-modal .tagline {
    text-align: center;
    color: #0F1F3B;
}

.checkout-modal .modal-body .modal-card {
    width: 200px;
    height: 175px;
    background: #EAEEF2;
    border: #707070;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.checkout-modal .modal-body {
    display: flex;
    justify-content: center;
}

.checkout-modal .modal-body .modal-card h4 {
    font-size: 18px;
    margin-top: 10px;
    font-family: "Poppins";
}





@media screen and (max-width: 1200px) {
    .m-sign-text h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 992px) {
    .m-logo {
        margin-bottom: 20px;
    }

    .m-sign-text {
        max-width: 100%;
        margin-bottom: 50px;
    }

    .m-sign-text h1 {
        font-size: 50px;
    }

    .m-signin {
        flex-direction: column-reverse;
    }

    .m-signin-sec2 {
        padding: 100px 0px !important;
        min-height: 100%;
    }

    .m-sign-text {
        margin-top: 0px;
    }

    .m-signin-content {
        padding-right: 1rem;
    }

    /*  Checkout */
    .m-checkout-content .m-order-summary {
        max-width: 300px;
    }

    .checkout-modal .modal-body .modal-card {
        width: 125px;
        height: 140px;
    }

    .checkout-modal .modal-body .modal-card img {
        width: 55px;
    }

    .checkout-modal .modal-body .modal-card h4 {
        font-size: 13px;
    }

}

@media screen and (max-width: 768px) {
    /*  Checkout */
    .m-checkout-page {
        flex-direction: column-reverse;
    }
   
    .m-checkout {
        height: 100%;
    }

    .m-checkout-content {
        /* margin-top: 25px; */
        padding: 2px;
        width: 100%;
        height: 100% !important;
        position: unset;
        right: 0px;
        bottom: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 50px;
        background-image: url('./images/updated-img/signIn-bg.png');
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
    }   
    .checkout-modal .modal-header {
        padding-top: 0.5rem !important;
    }

    .checkout-modal .modal-header div img {
        margin-top: 10px;
        width: 15px;
    }

    .checkout-modal .modal-header .modal-title {
        margin-top: 0px;
    }

    .checkout-modal .modal-body .modal-card {
        width: 130px;
        height: 150px;
    }

    .checkout-modal .modal-body .modal-card img {
        width: 60px;
    }

    .checkout-modal .modal-body .modal-card h4 {
        font-size: 13px;
    }
}

@media screen and (max-width: 576px) {
    .m-sign-text h1 {
        font-size: 40px;
    }

    .m-signup-form .form-input-2 {
        margin-left: 0%;
        margin-right: 0%;
    }
}

@media screen and (max-width: 500px) {
    .checkout-modal .modal-body .modal-card {
        width: 120px;
        height: 130px;
    }

    .checkout-modal .modal-body .modal-card img {
        width: 45px;
    }
}

@media screen and (max-width: 425px) {
    .m-sign-text h1 {
        font-size: 30px;
    }

    .m-signin-content {
        padding-top: 1rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    .m-sign-text {
        margin-bottom: 20px;
    }

    .m-signin-sec2 {
        padding: 50px 0px !important;
    }

    .checkout-modal .modal-body .modal-card {
        padding: 0%;
        width: 100px;
        height: 110px;
    }

    .checkout-modal .modal-body .modal-card img {
        width: 30px;
    }

    .checkout-modal .modal-body .modal-card h4 {
        font-size: 10px;
    }
}