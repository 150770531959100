.getStartedPage .row1 {
    padding: 30px;
    border-radius: 12px;
    background: white;
    text-align: center;
    margin-bottom: 25px;
}
.getStartedPage .row1 h1{
    font-size: 44px;
    color: #0F1F3B;
}
.getStartedPage .row1 p{
    font-size: 18px;
    color: #9d9d9d;
    margin: 0;
}
.getStartedPage .row2 {
    padding: 30px;
    border-radius: 12px;
    background: white;
    /* text-align: center; */
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.getStartedPage .row2 h3 {
    font-size: 26px;
    color: #0F1F3B;
}
.getStartedPage .row2 p {
    font-size: 14px;
    color: #9d9d9d;
    margin: 0;
}
/* .getStartedPage .payCard {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    border-radius: 12px;
    background: white;
    text-align: center;
    margin: 10px 10px;
    margin-bottom: 25px;
} */
.getStartedPage .payCard {
    display: flex;
    align-items: center;
    height: 90%;
    padding: 20px 0px;
    border-radius: 12px;
    background: white;
    text-align: center;
    margin: 10px 10px;
    margin-bottom: 25px;
}

.getStartedPage .payCard h3{
    font-size: 24px;
    color: #0F1F3B;
    text-align: start;
}
.getStartedPage .payCard .desc{
    font-size: 12px;
    color: #CACACA;
    text-align: start;
    min-height: 40px;
}
.getStartedPage .payCard .gradBtn{
    width: 100%;
    margin-top: 10px;
}
.getStartedPage .payCard .imgDiv{
    min-height: 180px;
    display: flex;align-items: center;
    justify-content: center;
}
@media (max-width: 1350px) {
    .getStartedPage .payCard .gradBtn {
        padding: 12px 20px;
    }
}

/* @media (max-width: 1260px) {
    .getStartedPage .payCard {
        display: flex;
        align-items: center;
        height: 90%;
        padding: 20px 0px;
        border-radius: 12px;
        background: white;
        text-align: center;
        margin: 10px 10px;
        margin-bottom: 25px;
    }
} */

@media (max-width: 1260px) {
    .getStartedPage .payCard h3 {
        font-size: 20px;
    }
}

@media screen and (max-width: 768px) {
    .getStartedPage .payCard h3{
        font-size: 24px;
        color: #0F1F3B;
        text-align: center;
    }
    .getStartedPage .payCard .desc{
        font-size: 16px;
        color: #CACACA;
        text-align: center;
        min-height: 40px;
    }
}

@media screen and (max-width: 600px) {
    .getStartedPage .row2{
        flex-direction: column;
    }
    .getStartedPage .row2 h3 {
        text-align: center;
    }
    .getStartedPage .row2 p {
        text-align: center;
        margin-bottom: 10px;
    }
}
